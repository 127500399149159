<template lang="html">
  <section class="informazioni">
    <b-container fluid>
      <b-row align-h="center">
        <b-col sm="12" class="text-center rounded bg-max mt-1 mb-1 p-3">
          <h2>Repertorio della poesia italiana tra Cinquecento e Seicento</h2>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="p-3 bg-max rounded border">
          <h3>Criteri di trascrizione</h3>

          <p>
            Per le antologie novecentesche ci si è limitati alla correzione dei
            refusi, a emendamenti congetturali, alla normalizzazione ortografica
            e a sporadici interventi sulla punteggiatura.
          </p>

          <b>Per le opere originali o dell'epoca:</b>

          <ul>
            <li>Interpunzione</li>
            <p>
              Dove l'edizione orginale mostra una presenza abbondante della
              virgola si preferisce un uso della stessa più contenuto.
              Generalmente, si rimuove davanti al che pronome relativo e davanti
              a congiunzioni coordinative di sostantivi e aggettivi. Si
              introduce, invece, prima o dopo i vocativi.<br />
              Quando i due punti non hanno evidente funzione dichiarativa, si
              trasformano in punto e virgola o in virgola conformemente ad una
              pausa più o meno forte.<br />
              Il punto posto a chiusura della strofa, ma non del periodo, si
              trasforma in una virgola o in un punto e virgola.<br />
              Il discorso diretto viene sempre introdotto con un trattino,
              qualora non sia presente nell'originale. Le citazioni si
              racchiudono tra virgolette.
            </p>

            <li>Ortografia</li>
            <p>
              Si rimuovono gli accenti sui monosillabi quali quì, fù, à, sù
              ecc.<br />
              All'apocope postvocalica del pronome io si aggiunge l'apostrofo,
              qualora non presente (es. i > i').<br />
              Si conservano tutte le aferesi.<br />
              Si aggiungono gli accenti a perche, poiche, benche, talche e al
              che causale.
            </p>

            <li>Grafie etimologiche</li>
            <p>
              Si rispetta la et davanti a vocale. La nota tironiana (&) è
              sciolta in e davanti a consonante e et davanti a vocale.<br />
              Si rimuovono tutte le h etimologiche (le forme al'hora, tal'hora,
              ogn'hora si rendono nelle rispettive: alora, talora, ognora).<br />
              Là dove il che è eliso con parole che iniziano per h, quest'ultima
              trasla al che (es. c'hor > ch'or).<br />
              La x latina si rende in ss quando è intervocalica, e in s negli
              altri casi.<br />
              Il segno grafico u in parole come uaga, auviene ecc. si riconduce
              a v.<br />
              I gruppi ti e tti che precedono la vocale si trasformano in zi e
              zzi.<br />
              Si conserva l'uso originale delle scempie (es. labra, improviso) e
              delle geminazioni (es. insessorabile).
            </p>

            <li>Maiuscole</li>
            <p>
              Si rimuovono a inizio del verso.<br />
              Si rimuovono quando si tratta dei nomi comuni di persone o di
              luoghi geografici generici (es. Pastorella, Ninfa, Oceano, Cielo).
              Si conservano quando si tratta di luoghi geografici o metafisici,
              o di personificazioni divine.
            </p>

            <li>Legamenti fra parole</li>
            <p>
              Le preposizioni articolate slegate si congiungono solo se nell'uso
              moderno esse non richiedono il raddoppiamento (es. de gli > degli,
              de le resta tale);<br />
              Si sciolgono le preposizioni articolate legate quando nell'uso
              moderno richiedono il raddoppiamento (es. ala > a la).<br />
              Si conservano le parole slegate quando ancora in uso nell'italiano
              moderno (es. in vece, vie più).
            </p>
          </ul>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script lang="js">
  export default  {
    name: 'informazioni',
    props: [],
    mounted() {},
    data() {
      return {};
    },
    methods: {},
    computed: {}
}
</script>

<style scoped lang="scss">
.informazioni {
  .bg-max {
    background: #b5c6ff;
  }
  li {
    font-weight: bold;
  }
}
</style>
